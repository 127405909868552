<template>
  <div class="container-fluid crm3">
    <card>
      <template v-slot:headerTitle>
        <ul class="list-unstyled calender-box mb-0">
          <li
            class="text-center"
            v-for="status in eventStatuses"
            :key="status.title"
          >
            <h4 class="mb-1">{{ status.title }}</h4>
            <span :class="status.textColor">
              <i :class="status.icon"></i>
              <span class="font-weight-bold h3">
                {{ status.count }}
              </span>
            </span>
          </li>
        </ul>
      </template>
      <template v-slot:body>
        <VueFullCalendar
          ref="fullCalendar"
          defaultView="dayGridMonth"
          :eventLimit="true"
          :header="header"
          :plugins="calendarPlugins"
          :events="formattedEvents"
          time-zone="UTC"
          @dateClick="handleDateClick"
          @eventClick="handleEventClick"
          :locale="locale"
          @eventRender="handleEventRender"
        />
      </template>
    </card>

    <!-- Modal para agregar/editar evento -->
    <b-modal
      v-model="modalVisible"
      :title="isEditing ? 'Editar Evento' : 'Agregar Evento'"
      hide-footer
    >
      <b-form @submit.prevent="isEditing ? updateEvent() : addEvent()">
        <b-form-group label="Título" label-for="event-title">
          <b-form-input
            id="event-title"
            v-model="currentEvent.title"
            :readonly="currentEvent.status === 'Completada'"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Fecha y Hora de Inicio"
          label-for="event-start-date"
        >
          <b-form-input
            id="event-start-date"
            type="datetime-local"
            v-model="currentEvent.startDate"
            :readonly="currentEvent.status === 'Completada'"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Fecha y Hora de Fin" label-for="event-end-date">
          <b-form-input
            id="event-end-date"
            type="datetime-local"
            v-model="currentEvent.endDate"
            :readonly="currentEvent.status === 'Completada'"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Descripción" label-for="event-desc">
          <b-form-textarea
            id="event-desc"
            v-model="currentEvent.description"
            rows="3"
            :readonly="currentEvent.status === 'Completada'"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Prioridad" label-for="event-priority">
          <b-form-select
            id="event-priority"
            class="custom-disabled"
            v-model="currentEvent.priority"
            :disabled="currentEvent.status === 'Completada'"
            :options="priorities"
            required
          ></b-form-select>
        </b-form-group>
        <div class="d-flex justify-content-between">
          <vs-button
            type="submit"
            :disabled="currentEvent.status === 'Completada'"
            >{{ isEditing ? "Actualizar" : "Agregar" }}</vs-button
          >
          <vs-button
            v-if="isEditing"
            type="button"
            :disabled="currentEvent.status === 'Completada'"
            success
            @click="completeEvent(currentEvent)"
          >
            <i class="fas fa-circle-check mr-1"></i>
            Completar Evento
          </vs-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import VueFullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";

export default {
  name: "Calendario",
  data() {
    return {
      header: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
      },
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
      ],
      calendarEvents: [
        {
          id: "1",
          title: "Reunión con el cliente",
          start: "2024-10-17T10:00:00",
          end: "2024-10-17T10:00:00",
          description: "Reunión para discutir los requisitos del proyecto.",
          status: "En progreso",
          priority: "Alta",
        },
        {
          id: "2",
          title: "Presentación del informe",
          start: "2024-10-24T14:00:00",
          end: "2024-10-26T15:00:00",
          description: "Presentación del informe trimestral.",
          status: "Completada",
          priority: "Media",
        },
        {
          id: "3",
          title: "Llamada con proveedor",
          start: "2024-11-02T09:00:00",
          end: "2024-11-02T10:00:00",
          description: "Llamada para revisar los plazos de entrega.",
          status: "En progreso",
          priority: "Baja",
        },
      ],
      locale: esLocale,
      modalVisible: false,
      isEditing: false,
      currentEvent: {
        id: null,
        title: "",
        startDate: "",
        endDate: "",
        description: "",
        status: "En progreso",
        priority: "",
      },
      eventStatuses: [
        {
          title: "En progreso",
          textColor: "text-warning",
          icon: "fas fa-clock",
          count: 0,
        },
        {
          title: "Completada",
          textColor: "text-success",
          icon: "fas fa-circle-check",
          count: 0,
        },
      ],
      priorities: ["Baja", "Media", "Alta"],
    };
  },
  computed: {
    formattedEvents() {
      // Formatear eventos para agregar color y icono según su estado y prioridad

      const statusIcons = {
        "En progreso": "fas fa-clock",
        Completada: "fas fa-circle-check",
      };
      return this.calendarEvents.map((event) => ({
        id: event.id,
        title: event.title,
        start: event.start,
        end: event.end,
        extendedProps: {
          description: event.description,
          status: event.status,
          priority: event.priority,
          icon: statusIcons[event.status],
        },
        color: this.getPriorityColor(event.priority),
      }));
    },
    eventCounts() {
      // Cuenta los eventos en progreso y completados
      return {
        inProgress: this.calendarEvents.filter(
          (event) => event.status === "En progreso"
        ).length,
        completed: this.calendarEvents.filter(
          (event) => event.status === "Completada"
        ).length,
      };
    },
  },
  watch: {
    eventCounts(newCounts) {
      this.eventStatuses[0].count = newCounts.inProgress;
      this.eventStatuses[1].count = newCounts.completed;
    },
  },
  components: {
    VueFullCalendar,
  },
  methods: {
    handleEventRender(eventInfo) {
      let $element = eventInfo.el;
      const { icon } = eventInfo.event.extendedProps;
      // const title = eventInfo.event.title;

      // let $content = `
      //   <span>
      //   <i class="${icon}"></i> ${title}
      //   </span>`;
      const $icon = document.createElement("i");
      $icon.className = icon;

      $element.classList.add("custom-fc-event");

      // $element.prepend($icon);

      console.log("renderEventContent", eventInfo);
    },
    getPriorityColor(priority) {
      // Devuelve el color según la prioridad
      switch (priority) {
        case "Alta":
          return "rgb(242, 19, 67)";
        case "Media":
          return "rgb(255, 130, 0)";
        case "Baja":
          return "#1A5CFF";
        default:
          return "rgb(0, 123, 146)";
      }
    },
    showModal() {
      this.modalVisible = true;
    },
    handleDateClick(date) {
      this.isEditing = false;

      const currentDate = new Date();
      const selectedDate = new Date(date.dateStr);

      // Establece la hora actual para el inicio
      this.currentEvent = {
        title: "",
        startDate:
          selectedDate.toISOString().slice(0, 10) +
          "T" +
          currentDate.toTimeString().slice(0, 5),
        endDate:
          selectedDate.toISOString().slice(0, 10) +
          "T" +
          currentDate.toTimeString().slice(0, 5),
        description: "",
        status: "En progreso",
        priority: "",
      };

      this.showModal();
    },

    handleEventClick(info) {
      this.isEditing = true;
      this.currentEvent = {
        id: info.event.id,
        title: info.event.title,
        startDate: info.event.start
          ? info.event.start.toISOString().slice(0, 16)
          : "",
        endDate: info.event.end
          ? info.event.end.toISOString().slice(0, 16)
          : info.event.start.toISOString().slice(0, 16),
        description: info.event.extendedProps.description,
        status: info.event.extendedProps.status,
        priority: info.event.extendedProps.priority,
      };
      this.showModal();
    },
    addEvent() {
      if (
        this.currentEvent.title.trim() === "" ||
        this.currentEvent.startDate.trim() === "" ||
        this.currentEvent.endDate.trim() === "" ||
        this.currentEvent.priority.trim() === ""
      ) {
        alert("Por favor, completa todos los campos obligatorios.");
        return;
      }
      try {
        this.calendarEvents.push({
          id: crypto.randomUUID(), // Generar UUID
          title: this.currentEvent.title,
          start: this.currentEvent.startDate,
          end: this.currentEvent.endDate,
          description: this.currentEvent.description,
          status: this.currentEvent.status,
          priority: this.currentEvent.priority,
        });
        this.modalVisible = false;
        this.currentEvent = {
          id: null,
          title: "",
          startDate: "",
          endDate: "",
          description: "",
          status: "En progreso",
          priority: "",
        };
      } catch (error) {
        console.error("Error al agregar el evento:", error);
        alert(
          "Ocurrió un error al agregar el evento. Por favor, intenta de nuevo."
        );
      }
    },
    updateEvent() {
      const index = this.calendarEvents.findIndex(
        (event) => event.id === this.currentEvent.id
      );

      if (index !== -1) {
        this.$set(this.calendarEvents, index, {
          ...this.calendarEvents[index],
          title: this.currentEvent.title,
          start: this.currentEvent.startDate,
          end: this.currentEvent.endDate,
          description: this.currentEvent.description,
          status: this.currentEvent.status,
          priority: this.currentEvent.priority,
        });

        console.log("actualizando", this.calendarEvents[index]);

        this.modalVisible = false;
      } else {
        alert("Error: No se encontró el evento para actualizar.");
      }
    },
    completeEvent(currentEvent) {
      const index = this.calendarEvents.findIndex(
        (event) => event.id === currentEvent.id
      );
      if (index !== -1) {
        // Actualizamos el estado e icono del evento
        this.$set(this.calendarEvents, index, {
          ...this.calendarEvents[index],
          status: "Completada",
        });

        this.modalVisible = false;

        console.log("actualizando", this.calendarEvents[index]);
      } else {
        alert("No se encontró el evento para completar.");
      }
    },
  },
};
</script>

<style lang="scss">
// .custom-fc-event {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 3px;
// }

@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@fullcalendar/list/main.min.css";
</style>
